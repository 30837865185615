export const UPDATE_PROFILE_START = 'UPDATE_PROFILE_START';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAIL = 'UPDATE_PROFILE_FAIL';
export const UPDATE_PROFILE_RESET = 'UPDATE_PROFILE_RESET';

export const RESET_PROFILE_STORE = 'RESET_PROFILE_STORE';

export const LOGOUT = 'LOGOUT';

export type ProfileActionTypes =
  | typeof UPDATE_PROFILE_START
  | typeof UPDATE_PROFILE_SUCCESS
  | typeof UPDATE_PROFILE_FAIL
  | typeof UPDATE_PROFILE_RESET
  | typeof RESET_PROFILE_STORE
  | typeof LOGOUT;
