export const FETCH_PAYMENTS_START = 'FETCH_PAYMENTS_START';
export const FETCH_PAYMENTS_SUCCESS = 'FETCH_PAYMENTS_SUCCESS';
export const FETCH_PAYMENTS_FAIL = 'FETCH_PAYMENTS_FAIL';

export const SEND_PAYMENTS_START = 'SEND_PAYMENTS_START';
export const SEND_PAYMENTS_SUCCESS = 'SEND_PAYMENTS_SUCCESS';
export const SEND_PAYMENTS_FAIL = 'SEND_PAYMENTS_FAIL';

export const REGENERATE_PAYMENTS_START = 'REGENERATE_PAYMENTS_START';
export const REGENERATE_PAYMENTS_SUCCESS = 'REGENERATE_PAYMENTS_SUCCESS';
export const REGENERATE_PAYMENTS_FAIL = 'REGENERATE_PAYMENTS_FAIL';

export const DOWNLOAD_INVOICE_START = 'DOWNLOAD_INVOICE_START';
export const DOWNLOAD_INVOICE_SUCCESS = 'DOWNLOAD_INVOICE_SUCCESS';
export const DOWNLOAD_INVOICE_FAIL = 'DOWNLOAD_INVOICE_FAIL';

export const DOWNLOAD_INVOICE_ADDITION_START =
  'DOWNLOAD_INVOICE_ADDITION_START';
export const DOWNLOAD_INVOICE_ADDITION_SUCCESS =
  'DOWNLOAD_INVOICE_ADDITION_SUCCESS';
export const DOWNLOAD_INVOICE_ADDITION_FAIL = 'DOWNLOAD_INVOICE_ADDITION_FAIL';

export const DOWNLOAD_INVOICE_ADDITION_BATCH_START =
  'DOWNLOAD_INVOICE_ADDITION_BATCH_START';
export const DOWNLOAD_INVOICE_ADDITION_BATCH_SUCCESS =
  'DOWNLOAD_INVOICE_ADDITION_BATCH_SUCCESS';
export const DOWNLOAD_INVOICE_ADDITION_BATCH_FAIL =
  'DOWNLOAD_INVOICE_ADDITION_BATCH_FAIL';

export const DOWNLOAD_INVOICE_BATCH_START = 'DOWNLOAD_INVOICE_BATCH_START';
export const DOWNLOAD_INVOICE_BATCH_SUCCESS = 'DOWNLOAD_INVOICE_BATCH_SUCCESS';
export const DOWNLOAD_INVOICE_BATCH_FAIL = 'DOWNLOAD_INVOICE_BATCH_FAIL';

export const UPDATE_INVOICE_LINES_START = 'UPDATE_INVOICE_LINES_START';
export const UPDATE_INVOICE_LINES_SUCCESS = 'UPDATE_INVOICE_LINES_SUCCESS';
export const UPDATE_INVOICE_LINES_FAIL = 'UPDATE_INVOICE_LINES_FAIL';

export const TOGGLE_PAYMENT_LOCK_STATUS_START =
  'TOGGLE_PAYMENT_LOCK_STATUS_START';
export const TOGGLE_PAYMENT_LOCK_STATUS_SUCCESS =
  'TOGGLE_PAYMENT_LOCK_STATUS_SUCCESS';
export const TOGGLE_PAYMENT_LOCK_STATUS_FAIL =
  'TOGGLE_PAYMENT_LOCK_STATUS_FAIL';

export const RESET_PAYMENT_STORE = 'RESET_PAYMENT_STORE';

export const LOGOUT = 'LOGOUT';

export type PaymentActionTypes =
  | typeof FETCH_PAYMENTS_START
  | typeof FETCH_PAYMENTS_SUCCESS
  | typeof FETCH_PAYMENTS_FAIL
  | typeof SEND_PAYMENTS_START
  | typeof SEND_PAYMENTS_SUCCESS
  | typeof SEND_PAYMENTS_FAIL
  | typeof REGENERATE_PAYMENTS_START
  | typeof REGENERATE_PAYMENTS_SUCCESS
  | typeof REGENERATE_PAYMENTS_FAIL
  | typeof DOWNLOAD_INVOICE_START
  | typeof DOWNLOAD_INVOICE_SUCCESS
  | typeof DOWNLOAD_INVOICE_FAIL
  | typeof DOWNLOAD_INVOICE_ADDITION_START
  | typeof DOWNLOAD_INVOICE_ADDITION_SUCCESS
  | typeof DOWNLOAD_INVOICE_ADDITION_FAIL
  | typeof DOWNLOAD_INVOICE_BATCH_START
  | typeof DOWNLOAD_INVOICE_BATCH_SUCCESS
  | typeof DOWNLOAD_INVOICE_BATCH_FAIL
  | typeof DOWNLOAD_INVOICE_ADDITION_BATCH_START
  | typeof DOWNLOAD_INVOICE_ADDITION_BATCH_SUCCESS
  | typeof DOWNLOAD_INVOICE_ADDITION_BATCH_FAIL
  | typeof UPDATE_INVOICE_LINES_START
  | typeof UPDATE_INVOICE_LINES_SUCCESS
  | typeof UPDATE_INVOICE_LINES_FAIL
  | typeof TOGGLE_PAYMENT_LOCK_STATUS_START
  | typeof TOGGLE_PAYMENT_LOCK_STATUS_SUCCESS
  | typeof TOGGLE_PAYMENT_LOCK_STATUS_FAIL
  | typeof RESET_PAYMENT_STORE
  | typeof LOGOUT;
