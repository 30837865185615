import * as actionTypes from './actionTypes';
import { ServiceGroupActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ServiceGroup } from '../../domain/ServiceGroup';

export type ServiceGroupStateType = {
  serviceGroups: ServiceGroup[];
  serviceGroupsLoading: boolean;
  serviceGroupsError: HttpError;
};

export type ServiceGroupActionType = ServiceGroupStateType & {
  type: ServiceGroupActionTypes;
};

export const initialState: ServiceGroupStateType = {
  serviceGroups: [],
  serviceGroupsLoading: true,
  serviceGroupsError: null,
};

const fetchServiceGroupsStart = (
  state: ServiceGroupStateType,
): ServiceGroupStateType => ({
  ...state,
  serviceGroupsLoading: true,
});

const fetchServiceGroupsSuccess = (
  state: ServiceGroupStateType,
  action: ServiceGroupActionType,
): ServiceGroupStateType => ({
  ...state,
  serviceGroups: action.serviceGroups,
  serviceGroupsLoading: false,
  serviceGroupsError: null,
});

const fetchServiceGroupsFail = (
  state: ServiceGroupStateType,
  action: ServiceGroupActionType,
): ServiceGroupStateType => ({
  ...state,
  serviceGroupsError: action.serviceGroupsError,
  serviceGroupsLoading: false,
});

const resetServiceGroupStore = (): ServiceGroupStateType => ({
  ...initialState,
});

const completelyResetServiceGroupStore = (): ServiceGroupStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: ServiceGroupActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_SERVICE_GROUPS_START:
      return fetchServiceGroupsStart(state);
    case actionTypes.FETCH_SERVICE_GROUPS_SUCCESS:
      return fetchServiceGroupsSuccess(state, action);
    case actionTypes.FETCH_SERVICE_GROUPS_FAIL:
      return fetchServiceGroupsFail(state, action);
    case actionTypes.RESET_SERVICE_GROUP_STORE:
      return resetServiceGroupStore();
    case actionTypes.LOGOUT:
      return completelyResetServiceGroupStore();
    default:
      return state;
  }
};

export default reducer;
