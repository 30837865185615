import * as actionTypes from './actionTypes';
import { ProfileActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';

export type ProfileStateType = {
  profileUpdateLoading: boolean;
  profileUpdateError: HttpError;
  profileUpdateSuccess: boolean;
};

export type ProfileActionType = ProfileStateType & {
  type: ProfileActionTypes;
};

export const initialState: ProfileStateType = {
  profileUpdateLoading: false,
  profileUpdateError: null,
  profileUpdateSuccess: false,
};

const updateProfileStart = (state: ProfileStateType): ProfileStateType => ({
  ...state,
  profileUpdateLoading: true,
});

const updateProfileSuccess = (state: ProfileStateType): ProfileStateType => ({
  ...state,
  profileUpdateLoading: false,
  profileUpdateSuccess: true,
});

const updateProfileFail = (
  state: ProfileStateType,
  action: ProfileActionType,
): ProfileStateType => ({
  ...state,
  profileUpdateError: action.profileUpdateError,
  profileUpdateLoading: false,
});

const updateProfileReset = (state: ProfileStateType): ProfileStateType => ({
  ...state,
  profileUpdateLoading: false,
  profileUpdateSuccess: false,
  profileUpdateError: null,
});

const resetProfileStore = (): ProfileStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: ProfileActionType) => {
  switch (action.type) {
    case actionTypes.UPDATE_PROFILE_START:
      return updateProfileStart(state);
    case actionTypes.UPDATE_PROFILE_SUCCESS:
      return updateProfileSuccess(state);
    case actionTypes.UPDATE_PROFILE_FAIL:
      return updateProfileFail(state, action);
    case actionTypes.UPDATE_PROFILE_RESET:
      return updateProfileReset(state);
    case actionTypes.RESET_PROFILE_STORE:
      return resetProfileStore();
    case actionTypes.LOGOUT:
      return resetProfileStore();
    default:
      return state;
  }
};

export default reducer;
