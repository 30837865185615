export const FETCH_UNITS_START = 'FETCH_UNITS_START';
export const FETCH_UNITS_SUCCESS = 'FETCH_UNITS_SUCCESS';
export const FETCH_UNITS_FAIL = 'FETCH_UNITS_FAIL';

export const RESET_UNIT_STORE = 'RESET_UNIT_STORE';

export const LOGOUT = 'LOGOUT';

export type UnitActionTypes =
  | typeof FETCH_UNITS_START
  | typeof FETCH_UNITS_SUCCESS
  | typeof FETCH_UNITS_FAIL
  | typeof RESET_UNIT_STORE
  | typeof LOGOUT;
