export const FETCH_SERVICE_GROUPS_START = 'FETCH_SERVICE_GROUPS_START';
export const FETCH_SERVICE_GROUPS_SUCCESS = 'FETCH_SERVICE_GROUPS_SUCCESS';
export const FETCH_SERVICE_GROUPS_FAIL = 'FETCH_SERVICE_GROUPS_FAIL';

export const RESET_SERVICE_GROUP_STORE = 'RESET_SERVICE_GROUP_STORE';

export const LOGOUT = 'LOGOUT';

export type ServiceGroupActionTypes =
  | typeof FETCH_SERVICE_GROUPS_START
  | typeof FETCH_SERVICE_GROUPS_SUCCESS
  | typeof FETCH_SERVICE_GROUPS_FAIL
  | typeof RESET_SERVICE_GROUP_STORE
  | typeof LOGOUT;
