import * as actionTypes from './actionTypes';
import { UnitActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Unit } from '../../domain/Unit';

export type UnitStateType = {
  units: Unit[];
  unitsLoading: boolean;
  unitsError: HttpError;
};

export type UnitActionType = UnitStateType & {
  type: UnitActionTypes;
};

export const initialState: UnitStateType = {
  units: [],
  unitsLoading: false,
  unitsError: null,
};

const fetchUnitsStart = (state: UnitStateType): UnitStateType => ({
  ...state,
  unitsLoading: true,
});

const fetchUnitsSuccess = (
  state: UnitStateType,
  action: UnitActionType,
): UnitStateType => ({
  ...state,
  units: action.units,
  unitsLoading: false,
  unitsError: null,
});

const fetchUnitsFail = (
  state: UnitStateType,
  action: UnitActionType,
): UnitStateType => ({
  ...state,
  unitsError: action.unitsError,
  unitsLoading: false,
});

const resetUnitStore = (): UnitStateType => ({
  ...initialState,
});

const completelyResetUnitStore = (): UnitStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: UnitActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_UNITS_START:
      return fetchUnitsStart(state);
    case actionTypes.FETCH_UNITS_SUCCESS:
      return fetchUnitsSuccess(state, action);
    case actionTypes.FETCH_UNITS_FAIL:
      return fetchUnitsFail(state, action);
    case actionTypes.RESET_UNIT_STORE:
      return resetUnitStore();
    case actionTypes.LOGOUT:
      return completelyResetUnitStore();
    default:
      return state;
  }
};

export default reducer;
