import React from 'react';
import { Provider } from 'react-redux';
import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  Store,
} from 'redux';
import thunk from 'redux-thunk';
import authReducer, { AuthStateType } from '../../store/auth/reducer';
import userReducer, { UserStateType } from '../../store/user/reducer';
import clientReducer, { ClientStateType } from '../../store/client/reducer';
import languageReducer, {
  LanguageStateType,
} from '../../store/language/reducer';
import translationReducer, {
  TranslationStateType,
} from '../../store/translation/reducer';
import instance from '../Axios/axios-instance';
import * as authService from '../../store/auth/actions';
import unitReducer, { UnitStateType } from '../../store/unit/reducer';
import serviceReducer, { ServiceStateType } from '../../store/service/reducer';
import employeeReducer, {
  EmployeeStateType,
} from '../../store/employee/reducer';
import workLogReducer, { WorkLogStateType } from '../../store/workLog/reducer';
import serviceGroupReducer, {
  ServiceGroupStateType,
} from '../../store/serviceGroup/reducer';
import profileReducer, { ProfileStateType } from '../../store/profile/reducer';
import tariffReducer, { TariffStateType } from '../../store/tariff/reducer';
import paymentReducer, { PaymentStateType } from '../../store/payment/reducer';

export type StoreState = {
  auth: AuthStateType;
  user: UserStateType;
  translation: TranslationStateType;
  language: LanguageStateType;
  client: ClientStateType;
  unit: UnitStateType;
  service: ServiceStateType;
  employee: EmployeeStateType;
  workLog: WorkLogStateType;
  serviceGroup: ServiceGroupStateType;
  profile: ProfileStateType;
  tariff: TariffStateType;
  payment: PaymentStateType;
};

type Props = {
  children?: any;
};

const rootReducer = combineReducers<StoreState>({
  auth: authReducer,
  user: userReducer,
  translation: translationReducer,
  language: languageReducer,
  client: clientReducer,
  unit: unitReducer,
  service: serviceReducer,
  employee: employeeReducer,
  workLog: workLogReducer,
  serviceGroup: serviceGroupReducer,
  profile: profileReducer,
  tariff: tariffReducer,
  payment: paymentReducer,
});

export const composeEnhancers =
  (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export const store: Store<any, any> = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk)),
);

const { dispatch } = store;
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response.status === 401 &&
      error.response.config.url !== '/token'
    ) {
      dispatch(authService.logout());
    }

    return Promise.reject(error);
  },
);

const StoreProvider = ({ children }: Props) => (
  <Provider store={store}>{children}</Provider>
);

export default StoreProvider;
