export const FETCH_TARIFF_START = 'FETCH_TARIFF_START';
export const FETCH_TARIFF_SUCCESS = 'FETCH_TARIFF_SUCCESS';
export const FETCH_TARIFF_FAIL = 'FETCH_TARIFF_FAIL';

export const FETCH_TARIFFS_START = 'FETCH_TARIFFS_START';
export const FETCH_TARIFFS_SUCCESS = 'FETCH_TARIFFS_SUCCESS';
export const FETCH_TARIFFS_FAIL = 'FETCH_TARIFFS_FAIL';

export const FETCH_ALL_TARIFFS_START = 'FETCH_ALL_TARIFFS_START';
export const FETCH_ALL_TARIFFS_SUCCESS = 'FETCH_ALL_TARIFFS_SUCCESS';
export const FETCH_ALL_TARIFFS_FAIL = 'FETCH_ALL_TARIFFS_FAIL';

export const CREATE_TARIFF_START = 'CREATE_TARIFF_START';
export const CREATE_TARIFF_SUCCESS = 'CREATE_TARIFF_SUCCESS';
export const CREATE_TARIFF_FAIL = 'CREATE_TARIFF_FAIL';

export const UPDATE_TARIFF_START = 'UPDATE_TARIFF_START';
export const UPDATE_TARIFF_SUCCESS = 'UPDATE_TARIFF_SUCCESS';
export const UPDATE_TARIFF_FAIL = 'UPDATE_TARIFF_FAIL';

export const DELETE_TARIFF_START = 'DELETE_TARIFF_START';
export const DELETE_TARIFF_SUCCESS = 'DELETE_TARIFF_SUCCESS';
export const DELETE_TARIFF_FAIL = 'DELETE_TARIFF_FAIL';

export const RESET_TARIFF_STORE = 'RESET_TARIFF_STORE';
export const RESET_CREATED_TARIFF_STORE = 'RESET_CREATED_TARIFF_STORE';

export const LOGOUT = 'LOGOUT';

export type TariffActionTypes =
  | typeof FETCH_TARIFF_START
  | typeof FETCH_TARIFF_SUCCESS
  | typeof FETCH_TARIFF_FAIL
  | typeof FETCH_TARIFFS_START
  | typeof FETCH_TARIFFS_SUCCESS
  | typeof FETCH_TARIFFS_FAIL
  | typeof FETCH_ALL_TARIFFS_START
  | typeof FETCH_ALL_TARIFFS_SUCCESS
  | typeof FETCH_ALL_TARIFFS_FAIL
  | typeof CREATE_TARIFF_START
  | typeof CREATE_TARIFF_SUCCESS
  | typeof CREATE_TARIFF_FAIL
  | typeof UPDATE_TARIFF_START
  | typeof UPDATE_TARIFF_SUCCESS
  | typeof UPDATE_TARIFF_FAIL
  | typeof DELETE_TARIFF_START
  | typeof DELETE_TARIFF_SUCCESS
  | typeof DELETE_TARIFF_FAIL
  | typeof RESET_TARIFF_STORE
  | typeof RESET_CREATED_TARIFF_STORE
  | typeof LOGOUT;
