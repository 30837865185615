import React from 'react';
import styles from './Navigation.module.scss';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { IconButton } from '@mui/material';
import {
  faBars,
  faSignOutAlt,
  faTimes,
  faUserCog,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as authService from '../../store/auth/service';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import useWindowSize from '../../hooks/useWindowSize/useWindowSize';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../config/Router/routes';

export type Props = {
  onLogout: () => void;
  onDrawerClick: () => void;
  isMobileMenuOpen: boolean;
};

const MOBILE_BREAK_POINT = 900;

export const Navigation = ({
  onLogout,
  onDrawerClick,
  isMobileMenuOpen,
}: Props) => {
  const navigate = useNavigate();
  const { width } = useWindowSize();

  return (
    <>
      <header className={styles.navigationContainer}>
        <div className={styles.iconItems}>
          {width && width < MOBILE_BREAK_POINT && (
            <IconButton onClick={() => onDrawerClick()}>
              <FontAwesomeIcon
                icon={(isMobileMenuOpen ? faTimes : faBars) as IconProp}
                fixedWidth
                size="sm"
              />
            </IconButton>
          )}
        </div>
        <div className={styles.iconItems}>
          <LanguageSwitcher />
          <IconButton onClick={() => navigate(routes.profile)}>
            <FontAwesomeIcon
              icon={faUserCog as IconProp}
              fixedWidth
              size="sm"
            />
          </IconButton>
          <IconButton onClick={() => onLogout()}>
            <FontAwesomeIcon
              icon={faSignOutAlt as IconProp}
              fixedWidth
              size="sm"
            />
          </IconButton>
        </div>
      </header>
    </>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onLogout: () => dispatch(authService.logoutUser()),
});

export default connect(null, mapDispatchToProps)(Navigation);
