import * as actionTypes from './actionTypes';
import { WorkLogActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { WorkLog } from '../../domain/WorkLog';

export type WorkLogStateType = {
  workLogList: ListResults<WorkLog> | null;
  workLogsLoading: boolean;
  workLogsError: HttpError;
  workLogCreateLoading: boolean;
  workLogCreateError: HttpError;
  workLogCreateSuccess: boolean;
  workLogUpdateLoading: boolean;
  workLogUpdateError: HttpError;
  workLogUpdateSuccess: boolean;
  workLogListUpdateNeeded: boolean;
  workLogDeleteLoading: boolean;
  workLogDeleteError: HttpError;
  workLogDeleteSuccess: boolean;
  workLogReportsLoading: boolean;
  workLogReportsError: HttpError;
  workLogReportsList: ListResults<WorkLog> | null;
};

export type WorkLogActionType = WorkLogStateType & {
  type: WorkLogActionTypes;
};

export const initialState: WorkLogStateType = {
  workLogList: null,
  workLogsLoading: false,
  workLogsError: null,
  workLogCreateLoading: false,
  workLogCreateError: null,
  workLogCreateSuccess: false,
  workLogUpdateLoading: false,
  workLogUpdateError: null,
  workLogUpdateSuccess: false,
  workLogListUpdateNeeded: false,
  workLogDeleteLoading: false,
  workLogDeleteError: null,
  workLogDeleteSuccess: false,
  workLogReportsLoading: false,
  workLogReportsError: null,
  workLogReportsList: null,
};

const fetchWorkLogsStart = (state: WorkLogStateType): WorkLogStateType => ({
  ...state,
  workLogsLoading: true,
});

const fetchWorkLogsSuccess = (
  state: WorkLogStateType,
  action: WorkLogActionType,
): WorkLogStateType => ({
  ...state,
  workLogList: action.workLogList,
  workLogsLoading: false,
  workLogsError: null,
  workLogListUpdateNeeded: false,
  workLogDeleteSuccess: false,
});

const fetchWorkLogsFail = (
  state: WorkLogStateType,
  action: WorkLogActionType,
): WorkLogStateType => ({
  ...state,
  workLogsError: action.workLogsError,
  workLogsLoading: false,
});

const createWorkLogStart = (state: WorkLogStateType): WorkLogStateType => ({
  ...state,
  workLogCreateLoading: true,
});

const createWorkLogSuccess = (state: WorkLogStateType): WorkLogStateType => ({
  ...state,
  workLogCreateLoading: false,
  workLogCreateError: null,
  workLogCreateSuccess: true,
  workLogListUpdateNeeded: true,
});

const createWorkLogFail = (
  state: WorkLogStateType,
  action: WorkLogActionType,
): WorkLogStateType => ({
  ...state,
  workLogCreateLoading: false,
  workLogCreateError: action.workLogCreateError,
});

const updateWorkLogStart = (state: WorkLogStateType): WorkLogStateType => ({
  ...state,
  workLogUpdateLoading: true,
});

const updateWorkLogSuccess = (state: WorkLogStateType): WorkLogStateType => ({
  ...state,
  workLogUpdateLoading: false,
  workLogUpdateError: null,
  workLogUpdateSuccess: true,
  workLogListUpdateNeeded: true,
});

const updateWorkLogFail = (
  state: WorkLogStateType,
  action: WorkLogActionType,
): WorkLogStateType => ({
  ...state,
  workLogUpdateLoading: false,
  workLogUpdateError: action.workLogUpdateError,
});

const deleteWorkLogStart = (state: WorkLogStateType): WorkLogStateType => ({
  ...state,
  workLogDeleteLoading: true,
});

const deleteWorkLogSuccess = (state: WorkLogStateType): WorkLogStateType => ({
  ...state,
  workLogDeleteLoading: false,
  workLogDeleteError: null,
  workLogDeleteSuccess: true,
  workLogListUpdateNeeded: true,
});

const deleteWorkLogFail = (
  state: WorkLogStateType,
  action: WorkLogActionType,
): WorkLogStateType => ({
  ...state,
  workLogDeleteLoading: false,
  workLogDeleteError: action.workLogUpdateError,
});

const fetchReportWorkLogsStart = (
  state: WorkLogStateType,
): WorkLogStateType => ({
  ...state,
  workLogReportsLoading: true,
});

const fetchReportWorkLogsSuccess = (
  state: WorkLogStateType,
  action: WorkLogActionType,
): WorkLogStateType => ({
  ...state,
  workLogReportsList: action.workLogReportsList,
  workLogReportsLoading: false,
  workLogReportsError: null,
  workLogDeleteSuccess: false,
  workLogListUpdateNeeded: false,
});

const fetchReportWorkLogsFail = (
  state: WorkLogStateType,
  action: WorkLogActionType,
): WorkLogStateType => ({
  ...state,
  workLogReportsError: action.workLogsError,
  workLogReportsLoading: false,
});

const resetWorkLogStore = (): WorkLogStateType => ({
  ...initialState,
});

const resetWorkLogCreateAndUpdate = (
  state: WorkLogStateType,
): WorkLogStateType => ({
  ...state,
  workLogCreateLoading: false,
  workLogCreateError: null,
  workLogCreateSuccess: false,
  workLogUpdateLoading: false,
  workLogUpdateError: null,
  workLogUpdateSuccess: false,
});

const reducer = (state = initialState, action: WorkLogActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_WORK_LOGS_START:
      return fetchWorkLogsStart(state);
    case actionTypes.FETCH_WORK_LOGS_SUCCESS:
      return fetchWorkLogsSuccess(state, action);
    case actionTypes.FETCH_WORK_LOGS_FAIL:
      return fetchWorkLogsFail(state, action);
    case actionTypes.CREATE_WORK_LOG_START:
      return createWorkLogStart(state);
    case actionTypes.CREATE_WORK_LOG_SUCCESS:
      return createWorkLogSuccess(state);
    case actionTypes.CREATE_WORK_LOG_FAIL:
      return createWorkLogFail(state, action);
    case actionTypes.UPDATE_WORK_LOG_START:
      return updateWorkLogStart(state);
    case actionTypes.UPDATE_WORK_LOG_SUCCESS:
      return updateWorkLogSuccess(state);
    case actionTypes.UPDATE_WORK_LOG_FAIL:
      return updateWorkLogFail(state, action);
    case actionTypes.DELETE_WORK_LOG_START:
      return deleteWorkLogStart(state);
    case actionTypes.DELETE_WORK_LOG_SUCCESS:
      return deleteWorkLogSuccess(state);
    case actionTypes.DELETE_WORK_LOG_FAIL:
      return deleteWorkLogFail(state, action);
    case actionTypes.FETCH_REPORT_WORK_LOGS_START:
      return fetchReportWorkLogsStart(state);
    case actionTypes.FETCH_REPORT_WORK_LOGS_SUCCESS:
      return fetchReportWorkLogsSuccess(state, action);
    case actionTypes.FETCH_REPORT_WORK_LOGS_FAIL:
      return fetchReportWorkLogsFail(state, action);
    case actionTypes.RESET_WORK_LOG_STORE:
      return resetWorkLogStore();
    case actionTypes.RESET_WORK_LOG_CREATE_AND_UPDATE:
      return resetWorkLogCreateAndUpdate(state);
    default:
      return state;
  }
};

export default reducer;
