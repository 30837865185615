export const FETCH_CLIENT_START = 'FETCH_CLIENT_START';
export const FETCH_CLIENT_SUCCESS = 'FETCH_CLIENT_SUCCESS';
export const FETCH_CLIENT_FAIL = 'FETCH_CLIENT_FAIL';

export const FETCH_CLIENTS_START = 'FETCH_CLIENTS_START';
export const FETCH_CLIENTS_SUCCESS = 'FETCH_CLIENTS_SUCCESS';
export const FETCH_CLIENTS_FAIL = 'FETCH_CLIENTS_FAIL';

export const CREATE_CLIENT_START = 'CREATE_CLIENT_START';
export const CREATE_CLIENT_SUCCESS = 'CREATE_CLIENT_SUCCESS';
export const CREATE_CLIENT_FAIL = 'CREATE_CLIENT_FAIL';

export const UPDATE_CLIENT_START = 'UPDATE_CLIENT_START';
export const UPDATE_CLIENT_SUCCESS = 'UPDATE_CLIENT_SUCCESS';
export const UPDATE_CLIENT_FAIL = 'UPDATE_CLIENT_FAIL';

export const DELETE_CLIENT_START = 'DELETE_CLIENT_START';
export const DELETE_CLIENT_SUCCESS = 'DELETE_CLIENT_SUCCESS';
export const DELETE_CLIENT_FAIL = 'DELETE_CLIENT_FAIL';

export const FETCH_ALL_CLIENTS_START = 'FETCH_ALL_CLIENTS_START';
export const FETCH_ALL_CLIENTS_SUCCESS = 'FETCH_ALL_CLIENTS_SUCCESS';
export const FETCH_ALL_CLIENTS_FAIL = 'FETCH_ALL_CLIENTS_FAIL';

export const FETCH_ALL_EMPLOYEE_CLIENTS_START =
  'FETCH_ALL_EMPLOYEE_CLIENTS_START';
export const FETCH_ALL_EMPLOYEE_CLIENTS_SUCCESS =
  'FETCH_ALL_EMPLOYEE_CLIENTS_SUCCESS';
export const FETCH_ALL_EMPLOYEE_CLIENTS_FAIL =
  'FETCH_ALL_EMPLOYEE_CLIENTS_FAIL';

export const TOGGLE_CLIENT_STATUS_START = 'TOGGLE_CLIENT_STATUS_START';
export const TOGGLE_CLIENT_STATUS_SUCCESS = 'TOGGLE_CLIENT_STATUS_SUCCESS';
export const TOGGLE_CLIENT_STATUS_FAIL = 'TOGGLE_CLIENT_STATUS_FAIL';

export const RESET_CLIENT_STORE = 'RESET_CLIENT_STORE';
export const RESET_CREATED_CLIENT_STORE = 'RESET_CREATED_CLIENT_STORE';

export const LOGOUT = 'LOGOUT';

export type ClientActionTypes =
  | typeof FETCH_CLIENT_START
  | typeof FETCH_CLIENT_SUCCESS
  | typeof FETCH_CLIENT_FAIL
  | typeof FETCH_CLIENTS_START
  | typeof FETCH_CLIENTS_SUCCESS
  | typeof FETCH_CLIENTS_FAIL
  | typeof CREATE_CLIENT_START
  | typeof CREATE_CLIENT_SUCCESS
  | typeof CREATE_CLIENT_FAIL
  | typeof UPDATE_CLIENT_START
  | typeof UPDATE_CLIENT_SUCCESS
  | typeof UPDATE_CLIENT_FAIL
  | typeof DELETE_CLIENT_START
  | typeof DELETE_CLIENT_SUCCESS
  | typeof DELETE_CLIENT_FAIL
  | typeof RESET_CLIENT_STORE
  | typeof RESET_CREATED_CLIENT_STORE
  | typeof FETCH_ALL_CLIENTS_START
  | typeof FETCH_ALL_CLIENTS_SUCCESS
  | typeof FETCH_ALL_CLIENTS_FAIL
  | typeof FETCH_ALL_EMPLOYEE_CLIENTS_START
  | typeof FETCH_ALL_EMPLOYEE_CLIENTS_SUCCESS
  | typeof FETCH_ALL_EMPLOYEE_CLIENTS_FAIL
  | typeof TOGGLE_CLIENT_STATUS_START
  | typeof TOGGLE_CLIENT_STATUS_SUCCESS
  | typeof TOGGLE_CLIENT_STATUS_FAIL
  | typeof LOGOUT;
