import * as actionTypes from './actionTypes';
import { TariffActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { Tariff } from '../../domain/Tariff';

export type TariffStateType = {
  tariff: Tariff | null;
  tariffLoading: boolean;
  tariffError: HttpError;
  tariffsList: ListResults<Tariff> | null;
  tariffsLoading: boolean;
  tariffsError: HttpError;
  tariffCreateLoading: boolean;
  tariffCreateError: HttpError;
  tariffCreateSuccess: boolean;
  createdTariff: Tariff | null;
  tariffUpdateLoading: boolean;
  tariffUpdateError: HttpError;
  tariffUpdateSuccess: boolean;
  tariffDeleteLoading: boolean;
  tariffDeleteError: HttpError;
  tariffDeleteSuccess: boolean;
  tariffListUpdateNeeded: boolean;
  allTariffs: Tariff[] | null;
  allTariffsError: HttpError;
  allTariffsLoading: boolean;
};

export type TariffActionType = TariffStateType & {
  type: TariffActionTypes;
};

export const initialState: TariffStateType = {
  tariff: null,
  tariffLoading: true,
  tariffError: null,
  tariffsList: null,
  tariffsLoading: true,
  tariffsError: null,
  tariffCreateLoading: false,
  tariffCreateSuccess: false,
  createdTariff: null,
  tariffCreateError: null,
  tariffUpdateLoading: false,
  tariffUpdateSuccess: false,
  tariffUpdateError: null,
  tariffDeleteLoading: false,
  tariffDeleteError: null,
  tariffDeleteSuccess: false,
  tariffListUpdateNeeded: false,
  allTariffs: null,
  allTariffsError: null,
  allTariffsLoading: false,
};

const fetchTariffStart = (state: TariffStateType): TariffStateType => ({
  ...state,
  tariffLoading: true,
});

const fetchTariffSuccess = (
  state: TariffStateType,
  action: TariffActionType,
): TariffStateType => ({
  ...state,
  tariff: action.tariff,
  tariffLoading: false,
  tariffError: null,
});

const fetchTariffFail = (
  state: TariffStateType,
  action: TariffActionType,
): TariffStateType => ({
  ...state,
  tariffError: action.tariffError,
  tariffLoading: false,
});

const fetchTariffsStart = (state: TariffStateType): TariffStateType => ({
  ...state,
  tariffsLoading: true,
  tariffDeleteSuccess: false,
  tariffUpdateSuccess: false,
  tariffCreateSuccess: false,
});

const fetchTariffsSuccess = (
  state: TariffStateType,
  action: TariffActionType,
): TariffStateType => ({
  ...state,
  tariffsList: action.tariffsList,
  tariffsLoading: false,
  tariffsError: null,
  tariffListUpdateNeeded: false,
});

const fetchTariffsFail = (
  state: TariffStateType,
  action: TariffActionType,
): TariffStateType => ({
  ...state,
  tariffsError: action.tariffsError,
  tariffsLoading: false,
});

const fetchAllTariffsStart = (state: TariffStateType): TariffStateType => ({
  ...state,
  allTariffsLoading: true,
});

const fetchAllTariffsSuccess = (
  state: TariffStateType,
  action: TariffActionType,
): TariffStateType => ({
  ...state,
  allTariffs: action.allTariffs,
  allTariffsLoading: false,
  allTariffsError: null,
});

const fetchAllTariffsFail = (
  state: TariffStateType,
  action: TariffActionType,
): TariffStateType => ({
  ...state,
  allTariffsError: action.allTariffsError,
  allTariffsLoading: false,
});

const createTariffStart = (state: TariffStateType): TariffStateType => ({
  ...state,
  tariffCreateLoading: true,
});

const createTariffSuccess = (
  state: TariffStateType,
  action: TariffActionType,
): TariffStateType => ({
  ...state,
  tariffCreateLoading: false,
  tariffCreateError: null,
  tariffCreateSuccess: true,
  tariffListUpdateNeeded: true,
  createdTariff: action.createdTariff,
});

const createTariffFail = (
  state: TariffStateType,
  action: TariffActionType,
): TariffStateType => ({
  ...state,
  tariffCreateLoading: false,
  tariffCreateError: action.tariffCreateError,
});

const updateTariffStart = (state: TariffStateType): TariffStateType => ({
  ...state,
  tariffUpdateLoading: true,
});

const updateTariffSuccess = (state: TariffStateType): TariffStateType => ({
  ...state,
  tariffUpdateLoading: false,
  tariffUpdateError: null,
  tariffUpdateSuccess: true,
  tariffListUpdateNeeded: true,
});

const updateTariffFail = (
  state: TariffStateType,
  action: TariffActionType,
): TariffStateType => ({
  ...state,
  tariffUpdateLoading: false,
  tariffUpdateError: action.tariffUpdateError,
});

const deleteTariffStart = (state: TariffStateType): TariffStateType => ({
  ...state,
  tariffDeleteLoading: true,
});

const deleteTariffSuccess = (state: TariffStateType): TariffStateType => ({
  ...state,
  tariffDeleteLoading: false,
  tariffDeleteError: null,
  tariffDeleteSuccess: true,
  tariffListUpdateNeeded: true,
});

const deleteTariffFail = (
  state: TariffStateType,
  action: TariffActionType,
): TariffStateType => ({
  ...state,
  tariffDeleteLoading: false,
  tariffDeleteError: action.tariffDeleteError,
});

const resetTariffStore = (): TariffStateType => ({
  ...initialState,
});

const completelyResetTariffStore = (): TariffStateType => ({
  ...initialState,
});

const resetCreatedTariffStore = (state: TariffStateType): TariffStateType => ({
  ...state,
  createdTariff: null,
  tariffUpdateError: null,
  tariffCreateError: null,
});

const reducer = (state = initialState, action: TariffActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_TARIFF_START:
      return fetchTariffStart(state);
    case actionTypes.FETCH_TARIFF_SUCCESS:
      return fetchTariffSuccess(state, action);
    case actionTypes.FETCH_TARIFF_FAIL:
      return fetchTariffFail(state, action);
    case actionTypes.FETCH_TARIFFS_START:
      return fetchTariffsStart(state);
    case actionTypes.FETCH_TARIFFS_SUCCESS:
      return fetchTariffsSuccess(state, action);
    case actionTypes.FETCH_TARIFFS_FAIL:
      return fetchTariffsFail(state, action);
    case actionTypes.CREATE_TARIFF_START:
      return createTariffStart(state);
    case actionTypes.CREATE_TARIFF_SUCCESS:
      return createTariffSuccess(state, action);
    case actionTypes.CREATE_TARIFF_FAIL:
      return createTariffFail(state, action);
    case actionTypes.UPDATE_TARIFF_START:
      return updateTariffStart(state);
    case actionTypes.UPDATE_TARIFF_SUCCESS:
      return updateTariffSuccess(state);
    case actionTypes.UPDATE_TARIFF_FAIL:
      return updateTariffFail(state, action);
    case actionTypes.DELETE_TARIFF_START:
      return deleteTariffStart(state);
    case actionTypes.DELETE_TARIFF_SUCCESS:
      return deleteTariffSuccess(state);
    case actionTypes.DELETE_TARIFF_FAIL:
      return deleteTariffFail(state, action);
    case actionTypes.FETCH_ALL_TARIFFS_START:
      return fetchAllTariffsStart(state);
    case actionTypes.FETCH_ALL_TARIFFS_SUCCESS:
      return fetchAllTariffsSuccess(state, action);
    case actionTypes.FETCH_ALL_TARIFFS_FAIL:
      return fetchAllTariffsFail(state, action);
    case actionTypes.RESET_TARIFF_STORE:
      return resetTariffStore();
    case actionTypes.RESET_CREATED_TARIFF_STORE:
      return resetCreatedTariffStore(state);
    case actionTypes.LOGOUT:
      return completelyResetTariffStore();
    default:
      return state;
  }
};

export default reducer;
