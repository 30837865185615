export const FETCH_SERVICE_START = 'FETCH_SERVICE_START';
export const FETCH_SERVICE_SUCCESS = 'FETCH_SERVICE_SUCCESS';
export const FETCH_SERVICE_FAIL = 'FETCH_SERVICE_FAIL';

export const FETCH_SERVICES_START = 'FETCH_SERVICES_START';
export const FETCH_SERVICES_SUCCESS = 'FETCH_SERVICES_SUCCESS';
export const FETCH_SERVICES_FAIL = 'FETCH_SERVICES_FAIL';

export const CREATE_SERVICE_START = 'CREATE_SERVICE_START';
export const CREATE_SERVICE_SUCCESS = 'CREATE_SERVICE_SUCCESS';
export const CREATE_SERVICE_FAIL = 'CREATE_SERVICE_FAIL';

export const UPDATE_SERVICE_START = 'UPDATE_SERVICE_START';
export const UPDATE_SERVICE_SUCCESS = 'UPDATE_SERVICE_SUCCESS';
export const UPDATE_SERVICE_FAIL = 'UPDATE_SERVICE_FAIL';

export const DELETE_SERVICE_START = 'DELETE_SERVICE_START';
export const DELETE_SERVICE_SUCCESS = 'DELETE_SERVICE_SUCCESS';
export const DELETE_SERVICE_FAIL = 'DELETE_SERVICE_FAIL';

export const FETCH_ALL_SERVICES_START = 'FETCH_ALL_SERVICES_START';
export const FETCH_ALL_SERVICES_SUCCESS = 'FETCH_ALL_SERVICES_SUCCESS';
export const FETCH_ALL_SERVICES_FAIL = 'FETCH_ALL_SERVICES_FAIL';

export const SET_SERVICE_SORT_ORDER_START = 'SET_SERVICE_SORT_ORDER_START';
export const SET_SERVICE_SORT_ORDER_SUCCESS = 'SET_SERVICE_SORT_ORDER_SUCCESS';
export const SET_SERVICE_SORT_ORDER_FAIL = 'SET_SERVICE_SORT_ORDER_FAIL';

export const RESET_SERVICE_STORE = 'RESET_SERVICE_STORE';

export const LOGOUT = 'LOGOUT';

export type ServiceActionTypes =
  | typeof FETCH_SERVICE_START
  | typeof FETCH_SERVICE_SUCCESS
  | typeof FETCH_SERVICE_FAIL
  | typeof FETCH_SERVICES_START
  | typeof FETCH_SERVICES_SUCCESS
  | typeof FETCH_SERVICES_FAIL
  | typeof CREATE_SERVICE_START
  | typeof CREATE_SERVICE_SUCCESS
  | typeof CREATE_SERVICE_FAIL
  | typeof UPDATE_SERVICE_START
  | typeof UPDATE_SERVICE_SUCCESS
  | typeof UPDATE_SERVICE_FAIL
  | typeof DELETE_SERVICE_START
  | typeof DELETE_SERVICE_SUCCESS
  | typeof DELETE_SERVICE_FAIL
  | typeof RESET_SERVICE_STORE
  | typeof FETCH_ALL_SERVICES_START
  | typeof FETCH_ALL_SERVICES_SUCCESS
  | typeof FETCH_ALL_SERVICES_FAIL
  | typeof SET_SERVICE_SORT_ORDER_START
  | typeof SET_SERVICE_SORT_ORDER_SUCCESS
  | typeof SET_SERVICE_SORT_ORDER_FAIL
  | typeof LOGOUT;
