import React, { ReactNode, useState, useEffect } from 'react';
import styles from './Layout.module.scss';
import Sidebar from '../Sidebar/Sidebar';
import { NavLink, useLocation } from 'react-router-dom';
import cx from 'classnames';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import useWindowSize from '../../hooks/useWindowSize/useWindowSize';
import Navigation from '../Navigation/Navigation';
import {
  faUsers,
  faGlobe,
  faUser,
  faBriefcase,
  faUserTie,
  faListCheck,
  faCashRegister,
  faMoneyBill,
} from '@fortawesome/free-solid-svg-icons';
import { routes } from '../../config/Router/routes';
import { useIntl } from 'react-intl';
import { translate } from '../../utility/messageTranslator/translate';
import { Language } from '../../domain/Language';
import { Locale } from '../../domain/Translation';
import { StoreState } from '../../config/StoreProvider/StoreProvider';
import { connect } from 'react-redux';
import { User } from '../../domain/User';
import { Role, Roles } from '../../domain/Role';

export type Props = {
  children: ReactNode;
  isAuthenticated: boolean;
  selectedLocale: Locale;
  languages: Language[];
  currentUser: User | null;
};

export type NavigationItem = {
  label: string;
  to: string;
  roles: Role[];
  icon: IconProp;
};

export type NavigationGroup = {
  label: string;
  roles: Role[];
  items: NavigationItem[];
};

const MOBILE_BREAK_POINT = 900;

const Layout = ({
  children,
  isAuthenticated,
  selectedLocale,
  languages,
  currentUser,
}: Props) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const intl = useIntl();

  const { width } = useWindowSize();
  const routeLocation = useLocation();

  useEffect(() => {
    setIsMobileMenuOpen(false);
  }, [routeLocation.key]);

  const SIDEBAR_ITEMS: NavigationGroup[] = [
    {
      label: translate(intl, 'NAVIGATION.GROUP_GLOBAL'),
      roles: [Roles.ADMIN],
      items: [
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_REPORTS'),
          to: routes.reports.list,
          icon: faListCheck as IconProp,
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_CLIENTS'),
          to: routes.clients.list,
          icon: faUserTie as IconProp,
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_EMPLOYEES'),
          to: routes.employees.list,
          icon: faUser as IconProp,
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_TARIFFS'),
          to: routes.tariffs.list,
          icon: faCashRegister as IconProp,
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_SERVICES'),
          to: routes.services.list,
          icon: faBriefcase as IconProp,
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_PAYMENTS'),
          to: routes.payments.list,
          icon: faMoneyBill as IconProp,
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_USERS'),
          to: routes.users.list,
          icon: faUsers as IconProp,
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_TRANSLATIONS'),
          to: routes.translations,
          icon: faGlobe as IconProp,
          roles: [Roles.ADMIN],
        },
      ],
    },
    {
      label: translate(intl, 'NAVIGATION.GROUP_GLOBAL'),
      roles: [Roles.EMPLOYEE],
      items: [
        {
          label: translate(intl, 'NAVIGATION.COMPLETED_SERVICES'),
          to: routes.completedServices.list,
          icon: faListCheck as IconProp,
          roles: [Roles.EMPLOYEE],
        },
      ],
    },
  ];

  useEffect(() => {
    if (isMobileMenuOpen) {
      window.scroll({ top: 0 });
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isMobileMenuOpen]);

  const getNavigationGroups = () => {
    const sidebarItems = SIDEBAR_ITEMS.filter(
      (item) => currentUser && item.roles.includes(currentUser.role),
    );

    return sidebarItems;
  };

  if (!isAuthenticated) {
    return <>{children}</>;
  }

  return (
    <>
      <div className={styles.container}>
        {width && width >= MOBILE_BREAK_POINT && (
          <Sidebar navigationGroups={getNavigationGroups()} />
        )}
        <div
          className={cx(styles.rightSection, {
            [styles.noScroll]: isMobileMenuOpen,
          })}
        >
          <Navigation
            onDrawerClick={() => setIsMobileMenuOpen((prev) => !prev)}
            isMobileMenuOpen={isMobileMenuOpen}
          />
          <div className={styles.content}>{children}</div>
        </div>
      </div>
      {isMobileMenuOpen && width && width < MOBILE_BREAK_POINT && (
        <div className={styles.mobileDrawer}>
          {getNavigationGroups().map((navigationGroup) => (
            <div className={styles.navigationGroup} key={navigationGroup.label}>
              <div className={styles.groupName}>{navigationGroup.label}</div>
              {navigationGroup.items
                .filter(
                  (navItem) =>
                    currentUser && navItem.roles.includes(currentUser.role),
                )
                .map((item) => (
                  <NavLink
                    key={item.label}
                    to={item.to}
                    className={({ isActive }) =>
                      cx(styles.navigationItem, {
                        [styles.activeSubItem]: isActive,
                      })
                    }
                  >
                    {item.label}
                  </NavLink>
                ))}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: StoreState) => ({
  selectedLocale: state.auth.selectedLocale,
  languages: state.language.languages,
  currentUser: state.user.currentUser,
});

export default connect(mapStateToProps)(Layout);
