import * as actionTypes from './actionTypes';
import { ServiceActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { Service } from '../../domain/Service';

export type ServiceStateType = {
  service: Service | null;
  serviceLoading: boolean;
  serviceError: HttpError;
  servicesList: ListResults<Service> | null;
  servicesLoading: boolean;
  servicesError: HttpError;
  serviceCreateLoading: boolean;
  serviceCreateError: HttpError;
  serviceCreateSuccess: boolean;
  serviceUpdateLoading: boolean;
  serviceUpdateError: HttpError;
  serviceUpdateSuccess: boolean;
  serviceDeleteLoading: boolean;
  serviceDeleteError: HttpError;
  serviceDeleteSuccess: boolean;
  serviceListUpdateNeeded: boolean;
  allServices: Service[];
  allServicesLoading: boolean;
  allServicesError: HttpError;
  serviceSortOrdersLoading: boolean;
  serviceSortOrdersError: HttpError;
  serviceSortOrdersSuccess: boolean;
};

export type ServiceActionType = ServiceStateType & {
  type: ServiceActionTypes;
};

export const initialState: ServiceStateType = {
  service: null,
  serviceLoading: true,
  serviceError: null,
  servicesList: null,
  servicesLoading: true,
  servicesError: null,
  serviceCreateLoading: false,
  serviceCreateSuccess: false,
  serviceCreateError: null,
  serviceUpdateLoading: false,
  serviceUpdateSuccess: false,
  serviceUpdateError: null,
  serviceDeleteLoading: false,
  serviceDeleteError: null,
  serviceDeleteSuccess: false,
  serviceListUpdateNeeded: false,
  allServices: [],
  allServicesLoading: true,
  allServicesError: null,
  serviceSortOrdersLoading: false,
  serviceSortOrdersError: null,
  serviceSortOrdersSuccess: false,
};

const fetchServiceStart = (state: ServiceStateType): ServiceStateType => ({
  ...state,
  serviceLoading: true,
});

const fetchServiceSuccess = (
  state: ServiceStateType,
  action: ServiceActionType,
): ServiceStateType => ({
  ...state,
  service: action.service,
  serviceLoading: false,
  serviceError: null,
});

const fetchServiceFail = (
  state: ServiceStateType,
  action: ServiceActionType,
): ServiceStateType => ({
  ...state,
  serviceError: action.serviceError,
  serviceLoading: false,
});

const fetchServicesStart = (state: ServiceStateType): ServiceStateType => ({
  ...state,
  servicesLoading: true,
});

const fetchServicesSuccess = (
  state: ServiceStateType,
  action: ServiceActionType,
): ServiceStateType => ({
  ...state,
  servicesList: action.servicesList,
  servicesLoading: false,
  servicesError: null,
  serviceListUpdateNeeded: false,
});

const fetchServicesFail = (
  state: ServiceStateType,
  action: ServiceActionType,
): ServiceStateType => ({
  ...state,
  servicesError: action.servicesError,
  servicesLoading: false,
});

const createServiceStart = (state: ServiceStateType): ServiceStateType => ({
  ...state,
  serviceCreateLoading: true,
});

const createServiceSuccess = (state: ServiceStateType): ServiceStateType => ({
  ...state,
  serviceCreateLoading: false,
  serviceCreateError: null,
  serviceCreateSuccess: true,
  serviceListUpdateNeeded: true,
});

const createServiceFail = (
  state: ServiceStateType,
  action: ServiceActionType,
): ServiceStateType => ({
  ...state,
  serviceCreateLoading: false,
  serviceCreateError: action.serviceCreateError,
});

const updateServiceStart = (state: ServiceStateType): ServiceStateType => ({
  ...state,
  serviceUpdateLoading: true,
});

const updateServiceSuccess = (state: ServiceStateType): ServiceStateType => ({
  ...state,
  serviceUpdateLoading: false,
  serviceUpdateError: null,
  serviceUpdateSuccess: true,
  serviceListUpdateNeeded: true,
});

const updateServiceFail = (
  state: ServiceStateType,
  action: ServiceActionType,
): ServiceStateType => ({
  ...state,
  serviceUpdateLoading: false,
  serviceUpdateError: action.serviceUpdateError,
});

const deleteServiceStart = (state: ServiceStateType): ServiceStateType => ({
  ...state,
  serviceDeleteLoading: true,
});

const deleteServiceSuccess = (state: ServiceStateType): ServiceStateType => ({
  ...state,
  serviceDeleteLoading: false,
  serviceDeleteError: null,
  serviceDeleteSuccess: true,
  serviceListUpdateNeeded: true,
});

const deleteServiceFail = (
  state: ServiceStateType,
  action: ServiceActionType,
): ServiceStateType => ({
  ...state,
  serviceDeleteLoading: false,
  serviceDeleteError: action.serviceDeleteError,
});

const resetServiceStore = (): ServiceStateType => ({
  ...initialState,
});

const fetchAllServicesStart = (state: ServiceStateType): ServiceStateType => ({
  ...state,
  allServicesLoading: true,
});

const fetchAllServicesSuccess = (
  state: ServiceStateType,
  action: ServiceActionType,
): ServiceStateType => ({
  ...state,
  allServices: action.allServices,
  allServicesLoading: false,
  allServicesError: null,
});

const fetchAllServicesFail = (
  state: ServiceStateType,
  action: ServiceActionType,
): ServiceStateType => ({
  ...state,
  allServicesError: action.allServicesError,
  allServicesLoading: false,
});

const setServiceSortOrdersStart = (
  state: ServiceStateType,
): ServiceStateType => ({
  ...state,
  serviceSortOrdersLoading: true,
  serviceSortOrdersSuccess: false,
});

const setServiceSortOrdersSuccess = (
  state: ServiceStateType,
): ServiceStateType => ({
  ...state,
  serviceSortOrdersLoading: false,
  serviceSortOrdersError: null,
  serviceListUpdateNeeded: true,
  serviceSortOrdersSuccess: true,
});

const setServiceSortOrdersFail = (
  state: ServiceStateType,
  action: ServiceActionType,
): ServiceStateType => ({
  ...state,
  serviceSortOrdersError: action.serviceSortOrdersError,
  serviceSortOrdersSuccess: false,
  serviceSortOrdersLoading: false,
});

const completelyResetServiceStore = (): ServiceStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: ServiceActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_SERVICE_START:
      return fetchServiceStart(state);
    case actionTypes.FETCH_SERVICE_SUCCESS:
      return fetchServiceSuccess(state, action);
    case actionTypes.FETCH_SERVICE_FAIL:
      return fetchServiceFail(state, action);
    case actionTypes.FETCH_SERVICES_START:
      return fetchServicesStart(state);
    case actionTypes.FETCH_SERVICES_SUCCESS:
      return fetchServicesSuccess(state, action);
    case actionTypes.FETCH_SERVICES_FAIL:
      return fetchServicesFail(state, action);
    case actionTypes.CREATE_SERVICE_START:
      return createServiceStart(state);
    case actionTypes.CREATE_SERVICE_SUCCESS:
      return createServiceSuccess(state);
    case actionTypes.CREATE_SERVICE_FAIL:
      return createServiceFail(state, action);
    case actionTypes.UPDATE_SERVICE_START:
      return updateServiceStart(state);
    case actionTypes.UPDATE_SERVICE_SUCCESS:
      return updateServiceSuccess(state);
    case actionTypes.UPDATE_SERVICE_FAIL:
      return updateServiceFail(state, action);
    case actionTypes.DELETE_SERVICE_START:
      return deleteServiceStart(state);
    case actionTypes.DELETE_SERVICE_SUCCESS:
      return deleteServiceSuccess(state);
    case actionTypes.DELETE_SERVICE_FAIL:
      return deleteServiceFail(state, action);
    case actionTypes.RESET_SERVICE_STORE:
      return resetServiceStore();
    case actionTypes.FETCH_ALL_SERVICES_START:
      return fetchAllServicesStart(state);
    case actionTypes.FETCH_ALL_SERVICES_SUCCESS:
      return fetchAllServicesSuccess(state, action);
    case actionTypes.FETCH_ALL_SERVICES_FAIL:
      return fetchAllServicesFail(state, action);
    case actionTypes.SET_SERVICE_SORT_ORDER_START:
      return setServiceSortOrdersStart(state);
    case actionTypes.SET_SERVICE_SORT_ORDER_SUCCESS:
      return setServiceSortOrdersSuccess(state);
    case actionTypes.SET_SERVICE_SORT_ORDER_FAIL:
      return setServiceSortOrdersFail(state, action);
    case actionTypes.LOGOUT:
      return completelyResetServiceStore();
    default:
      return state;
  }
};

export default reducer;
