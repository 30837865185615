import * as actionTypes from './actionTypes';
import { ClientActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { Client } from '../../domain/Client';

export type ClientStateType = {
  client: Client | null;
  clientLoading: boolean;
  clientError: HttpError;
  clientsList: ListResults<Client> | null;
  clientsLoading: boolean;
  clientsError: HttpError;
  clientCreateLoading: boolean;
  clientCreateError: HttpError;
  clientCreateSuccess: boolean;
  createdClient: Client | null;
  clientUpdateLoading: boolean;
  clientUpdateError: HttpError;
  clientUpdateSuccess: boolean;
  clientDeleteLoading: boolean;
  clientDeleteError: HttpError;
  clientDeleteSuccess: boolean;
  clientListUpdateNeeded: boolean;
  allClients: Client[];
  allClientsLoading: boolean;
  allClientsError: HttpError;
  allEmployeeClients: Client[];
  allEmployeeClientsLoading: boolean;
  allEmployeeClientsError: HttpError;
  toggleStatusLoading: boolean;
  toggleStatusError: HttpError;
};

export type ClientActionType = ClientStateType & {
  type: ClientActionTypes;
};

export const initialState: ClientStateType = {
  client: null,
  clientLoading: true,
  clientError: null,
  clientsList: null,
  clientsLoading: true,
  clientsError: null,
  clientCreateLoading: false,
  clientCreateSuccess: false,
  createdClient: null,
  clientCreateError: null,
  clientUpdateLoading: false,
  clientUpdateSuccess: false,
  clientUpdateError: null,
  clientDeleteLoading: false,
  clientDeleteError: null,
  clientDeleteSuccess: false,
  clientListUpdateNeeded: false,
  allClients: [],
  allClientsLoading: true,
  allClientsError: null,
  allEmployeeClients: [],
  allEmployeeClientsLoading: true,
  allEmployeeClientsError: null,
  toggleStatusError: null,
  toggleStatusLoading: false,
};

const fetchClientStart = (state: ClientStateType): ClientStateType => ({
  ...state,
  clientLoading: true,
});

const fetchClientSuccess = (
  state: ClientStateType,
  action: ClientActionType,
): ClientStateType => ({
  ...state,
  client: action.client,
  clientLoading: false,
  clientError: null,
});

const fetchClientFail = (
  state: ClientStateType,
  action: ClientActionType,
): ClientStateType => ({
  ...state,
  clientError: action.clientError,
  clientLoading: false,
});

const fetchClientsStart = (state: ClientStateType): ClientStateType => ({
  ...state,
  clientsLoading: true,
  clientDeleteSuccess: false,
});

const fetchClientsSuccess = (
  state: ClientStateType,
  action: ClientActionType,
): ClientStateType => ({
  ...state,
  clientsList: action.clientsList,
  clientsLoading: false,
  clientsError: null,
  clientListUpdateNeeded: false,
});

const fetchClientsFail = (
  state: ClientStateType,
  action: ClientActionType,
): ClientStateType => ({
  ...state,
  clientsError: action.clientsError,
  clientsLoading: false,
});

const createClientStart = (state: ClientStateType): ClientStateType => ({
  ...state,
  clientCreateLoading: true,
});

const createClientSuccess = (
  state: ClientStateType,
  action: ClientActionType,
): ClientStateType => ({
  ...state,
  clientCreateLoading: false,
  clientCreateError: null,
  clientCreateSuccess: true,
  clientListUpdateNeeded: true,
  createdClient: action.createdClient,
});

const createClientFail = (
  state: ClientStateType,
  action: ClientActionType,
): ClientStateType => ({
  ...state,
  clientCreateLoading: false,
  clientCreateError: action.clientCreateError,
});

const updateClientStart = (state: ClientStateType): ClientStateType => ({
  ...state,
  clientUpdateLoading: true,
});

const updateClientSuccess = (state: ClientStateType): ClientStateType => ({
  ...state,
  clientUpdateLoading: false,
  clientUpdateError: null,
  clientUpdateSuccess: true,
  clientListUpdateNeeded: true,
});

const updateClientFail = (
  state: ClientStateType,
  action: ClientActionType,
): ClientStateType => ({
  ...state,
  clientUpdateLoading: false,
  clientUpdateError: action.clientUpdateError,
});

const deleteClientStart = (state: ClientStateType): ClientStateType => ({
  ...state,
  clientDeleteLoading: true,
});

const deleteClientSuccess = (state: ClientStateType): ClientStateType => ({
  ...state,
  clientDeleteLoading: false,
  clientDeleteError: null,
  clientDeleteSuccess: true,
  clientListUpdateNeeded: true,
});

const deleteClientFail = (
  state: ClientStateType,
  action: ClientActionType,
): ClientStateType => ({
  ...state,
  clientDeleteLoading: false,
  clientDeleteError: action.clientDeleteError,
});

const resetClientStore = (): ClientStateType => ({
  ...initialState,
});

const fetchAllClientsStart = (state: ClientStateType): ClientStateType => ({
  ...state,
  allClientsLoading: true,
});

const fetchAllClientsSuccess = (
  state: ClientStateType,
  action: ClientActionType,
): ClientStateType => ({
  ...state,
  allClients: action.allClients,
  allClientsLoading: false,
  allClientsError: null,
});

const fetchAllClientsFail = (
  state: ClientStateType,
  action: ClientActionType,
): ClientStateType => ({
  ...state,
  allClientsError: action.allClientsError,
  allClientsLoading: false,
});

const fetchAllEmployeeClientsStart = (
  state: ClientStateType,
): ClientStateType => ({
  ...state,
  allEmployeeClientsLoading: true,
});

const fetchAllEmployeeClientsSuccess = (
  state: ClientStateType,
  action: ClientActionType,
): ClientStateType => ({
  ...state,
  allEmployeeClients: action.allEmployeeClients,
  allEmployeeClientsLoading: false,
  allEmployeeClientsError: null,
});

const fetchAllEmployeeClientsFail = (
  state: ClientStateType,
  action: ClientActionType,
): ClientStateType => ({
  ...state,
  allEmployeeClientsError: action.allEmployeeClientsError,
  allEmployeeClientsLoading: false,
});

const toggleClientStatusStart = (state: ClientStateType): ClientStateType => ({
  ...state,
  toggleStatusLoading: true,
});

const toggleClientStatusSuccess = (
  state: ClientStateType,
  action: ClientActionType,
): ClientStateType => ({
  ...state,
  toggleStatusLoading: false,
  toggleStatusError: null,
  clientListUpdateNeeded: true,
});

const toggleClientStatusFail = (
  state: ClientStateType,
  action: ClientActionType,
): ClientStateType => ({
  ...state,
  toggleStatusLoading: false,
  toggleStatusError: action.toggleStatusError,
});

const completelyResetClientStore = (): ClientStateType => ({
  ...initialState,
});

const resetCreatedClientStore = (state: ClientStateType): ClientStateType => ({
  ...state,
  createdClient: null,
});

const reducer = (state = initialState, action: ClientActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_CLIENT_START:
      return fetchClientStart(state);
    case actionTypes.FETCH_CLIENT_SUCCESS:
      return fetchClientSuccess(state, action);
    case actionTypes.FETCH_CLIENT_FAIL:
      return fetchClientFail(state, action);
    case actionTypes.FETCH_CLIENTS_START:
      return fetchClientsStart(state);
    case actionTypes.FETCH_CLIENTS_SUCCESS:
      return fetchClientsSuccess(state, action);
    case actionTypes.FETCH_CLIENTS_FAIL:
      return fetchClientsFail(state, action);
    case actionTypes.CREATE_CLIENT_START:
      return createClientStart(state);
    case actionTypes.CREATE_CLIENT_SUCCESS:
      return createClientSuccess(state, action);
    case actionTypes.CREATE_CLIENT_FAIL:
      return createClientFail(state, action);
    case actionTypes.UPDATE_CLIENT_START:
      return updateClientStart(state);
    case actionTypes.UPDATE_CLIENT_SUCCESS:
      return updateClientSuccess(state);
    case actionTypes.UPDATE_CLIENT_FAIL:
      return updateClientFail(state, action);
    case actionTypes.DELETE_CLIENT_START:
      return deleteClientStart(state);
    case actionTypes.DELETE_CLIENT_SUCCESS:
      return deleteClientSuccess(state);
    case actionTypes.DELETE_CLIENT_FAIL:
      return deleteClientFail(state, action);
    case actionTypes.FETCH_ALL_CLIENTS_START:
      return fetchAllClientsStart(state);
    case actionTypes.FETCH_ALL_CLIENTS_SUCCESS:
      return fetchAllClientsSuccess(state, action);
    case actionTypes.FETCH_ALL_CLIENTS_FAIL:
      return fetchAllClientsFail(state, action);
    case actionTypes.FETCH_ALL_EMPLOYEE_CLIENTS_START:
      return fetchAllEmployeeClientsStart(state);
    case actionTypes.FETCH_ALL_EMPLOYEE_CLIENTS_SUCCESS:
      return fetchAllEmployeeClientsSuccess(state, action);
    case actionTypes.FETCH_ALL_EMPLOYEE_CLIENTS_FAIL:
      return fetchAllEmployeeClientsFail(state, action);
    case actionTypes.TOGGLE_CLIENT_STATUS_START:
      return toggleClientStatusStart(state);
    case actionTypes.TOGGLE_CLIENT_STATUS_SUCCESS:
      return toggleClientStatusSuccess(state, action);
    case actionTypes.TOGGLE_CLIENT_STATUS_FAIL:
      return toggleClientStatusFail(state, action);
    case actionTypes.RESET_CLIENT_STORE:
      return resetClientStore();
    case actionTypes.RESET_CREATED_CLIENT_STORE:
      return resetCreatedClientStore(state);
    case actionTypes.LOGOUT:
      return completelyResetClientStore();
    default:
      return state;
  }
};

export default reducer;
