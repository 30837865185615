export const FETCH_EMPLOYEE_START = 'FETCH_EMPLOYEE_START';
export const FETCH_EMPLOYEE_SUCCESS = 'FETCH_EMPLOYEE_SUCCESS';
export const FETCH_EMPLOYEE_FAIL = 'FETCH_EMPLOYEE_FAIL';

export const FETCH_EMPLOYEES_START = 'FETCH_EMPLOYEES_START';
export const FETCH_EMPLOYEES_SUCCESS = 'FETCH_EMPLOYEES_SUCCESS';
export const FETCH_EMPLOYEES_FAIL = 'FETCH_EMPLOYEES_FAIL';

export const CREATE_EMPLOYEE_START = 'CREATE_EMPLOYEE_START';
export const CREATE_EMPLOYEE_SUCCESS = 'CREATE_EMPLOYEE_SUCCESS';
export const CREATE_EMPLOYEE_FAIL = 'CREATE_EMPLOYEE_FAIL';

export const UPDATE_EMPLOYEE_START = 'UPDATE_EMPLOYEE_START';
export const UPDATE_EMPLOYEE_SUCCESS = 'UPDATE_EMPLOYEE_SUCCESS';
export const UPDATE_EMPLOYEE_FAIL = 'UPDATE_EMPLOYEE_FAIL';

export const DELETE_EMPLOYEE_START = 'DELETE_EMPLOYEE_START';
export const DELETE_EMPLOYEE_SUCCESS = 'DELETE_EMPLOYEE_SUCCESS';
export const DELETE_EMPLOYEE_FAIL = 'DELETE_EMPLOYEE_FAIL';

export const CHANGE_EMPLOYEE_STATUS_START = 'CHANGE_EMPLOYEE_STATUS_START';
export const CHANGE_EMPLOYEE_STATUS_SUCCESS = 'CHANGE_EMPLOYEE_STATUS_SUCCESS';
export const CHANGE_EMPLOYEE_STATUS_FAIL = 'CHANGE_EMPLOYEE_STATUS_FAIL';

export const FETCH_ALL_EMPLOYEES_START = 'FETCH_ALL_EMPLOYEES_START';
export const FETCH_ALL_EMPLOYEES_SUCCESS = 'FETCH_ALL_EMPLOYEES_SUCCESS';
export const FETCH_ALL_EMPLOYEES_FAIL = 'FETCH_ALL_EMPLOYEES_FAIL';

export const RESET_EMPLOYEE_STORE = 'RESET_EMPLOYEE_STORE';

export const LOGOUT = 'LOGOUT';

export type EmployeeActionTypes =
  | typeof FETCH_EMPLOYEE_START
  | typeof FETCH_EMPLOYEE_SUCCESS
  | typeof FETCH_EMPLOYEE_FAIL
  | typeof FETCH_EMPLOYEES_START
  | typeof FETCH_EMPLOYEES_SUCCESS
  | typeof FETCH_EMPLOYEES_FAIL
  | typeof CREATE_EMPLOYEE_START
  | typeof CREATE_EMPLOYEE_SUCCESS
  | typeof CREATE_EMPLOYEE_FAIL
  | typeof UPDATE_EMPLOYEE_START
  | typeof UPDATE_EMPLOYEE_SUCCESS
  | typeof UPDATE_EMPLOYEE_FAIL
  | typeof DELETE_EMPLOYEE_START
  | typeof DELETE_EMPLOYEE_SUCCESS
  | typeof DELETE_EMPLOYEE_FAIL
  | typeof RESET_EMPLOYEE_STORE
  | typeof CHANGE_EMPLOYEE_STATUS_START
  | typeof CHANGE_EMPLOYEE_STATUS_SUCCESS
  | typeof CHANGE_EMPLOYEE_STATUS_FAIL
  | typeof FETCH_ALL_EMPLOYEES_START
  | typeof FETCH_ALL_EMPLOYEES_SUCCESS
  | typeof FETCH_ALL_EMPLOYEES_FAIL
  | typeof LOGOUT;
