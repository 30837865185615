export const routes = {
  homepage: '/admin',
  login: '/login',
  profile: '/profile',
  dynamicPage: '/:locale/:slug',
  admin: '/admin',
  users: {
    list: '/admin/users',
    create: '/admin/users/new',
    edit: '/admin/users/:id',
  },
  clients: {
    list: '/admin/clients',
    create: '/admin/clients/new',
    edit: '/admin/clients/:id',
  },
  services: {
    list: '/admin/services',
    create: '/admin/services/new',
    edit: '/admin/services/:id',
  },
  employees: {
    list: '/admin/employees',
    create: '/admin/employees/new',
    edit: '/admin/employees/:id',
  },
  tariffs: {
    list: '/admin/tariffs',
  },
  reports: {
    list: '/admin/reports',
  },
  payments: {
    list: '/admin/payments',
  },
  translations: '/admin/translations',
  completedServices: {
    list: '/employee/completed-services',
  },
};
