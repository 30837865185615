import * as actionTypes from './actionTypes';
import { EmployeeActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { Employee } from 'src/domain/Employee';

export type EmployeeStateType = {
  employee: Employee | null;
  employeeLoading: boolean;
  employeeError: HttpError;
  employeesList: ListResults<Employee> | null;
  employeesLoading: boolean;
  employeesError: HttpError;
  employeeCreateLoading: boolean;
  employeeCreateError: HttpError;
  employeeCreateSuccess: boolean;
  employeeUpdateLoading: boolean;
  employeeUpdateError: HttpError;
  employeeUpdateSuccess: boolean;
  employeeDeleteLoading: boolean;
  employeeDeleteError: HttpError;
  employeeDeleteSuccess: boolean;
  employeeListUpdateNeeded: boolean;
  changeEmployeeStatusLoading: boolean;
  changeEmployeeStatusError: HttpError;
  changeEmployeeStatusSuccess: boolean;
  allEmployeesLoading: boolean;
  allEmployeesError: HttpError;
  allEmployees: Employee[] | [];
};

export type EmployeeActionType = EmployeeStateType & {
  type: EmployeeActionTypes;
};

export const initialState: EmployeeStateType = {
  employee: null,
  employeeLoading: true,
  employeeError: null,
  employeesList: null,
  employeesLoading: true,
  employeesError: null,
  employeeCreateLoading: false,
  employeeCreateSuccess: false,
  employeeCreateError: null,
  employeeUpdateLoading: false,
  employeeUpdateSuccess: false,
  employeeUpdateError: null,
  employeeDeleteLoading: false,
  employeeDeleteError: null,
  employeeDeleteSuccess: false,
  employeeListUpdateNeeded: false,
  changeEmployeeStatusLoading: false,
  changeEmployeeStatusError: null,
  changeEmployeeStatusSuccess: false,
  allEmployeesLoading: false,
  allEmployeesError: null,
  allEmployees: [],
};

const fetchEmployeeStart = (state: EmployeeStateType): EmployeeStateType => ({
  ...state,
  employeeLoading: true,
});

const fetchEmployeeSuccess = (
  state: EmployeeStateType,
  action: EmployeeActionType,
): EmployeeStateType => ({
  ...state,
  employee: action.employee,
  employeeLoading: false,
  employeeError: null,
});

const fetchEmployeeFail = (
  state: EmployeeStateType,
  action: EmployeeActionType,
): EmployeeStateType => ({
  ...state,
  employeeError: action.employeeError,
  employeeLoading: false,
});

const fetchEmployeesStart = (state: EmployeeStateType): EmployeeStateType => ({
  ...state,
  employeesLoading: true,
});

const fetchEmployeesSuccess = (
  state: EmployeeStateType,
  action: EmployeeActionType,
): EmployeeStateType => ({
  ...state,
  employeesList: action.employeesList,
  employeesLoading: false,
  employeesError: null,
  employeeListUpdateNeeded: false,
});

const fetchEmployeesFail = (
  state: EmployeeStateType,
  action: EmployeeActionType,
): EmployeeStateType => ({
  ...state,
  employeesError: action.employeesError,
  employeesLoading: false,
});

const createEmployeeStart = (state: EmployeeStateType): EmployeeStateType => ({
  ...state,
  employeeCreateLoading: true,
});

const createEmployeeSuccess = (
  state: EmployeeStateType,
): EmployeeStateType => ({
  ...state,
  employeeCreateLoading: false,
  employeeCreateError: null,
  employeeCreateSuccess: true,
  employeeListUpdateNeeded: true,
});

const createEmployeeFail = (
  state: EmployeeStateType,
  action: EmployeeActionType,
): EmployeeStateType => ({
  ...state,
  employeeCreateLoading: false,
  employeeCreateError: action.employeeCreateError,
});

const updateEmployeeStart = (state: EmployeeStateType): EmployeeStateType => ({
  ...state,
  employeeUpdateLoading: true,
});

const updateEmployeeSuccess = (
  state: EmployeeStateType,
): EmployeeStateType => ({
  ...state,
  employeeUpdateLoading: false,
  employeeUpdateError: null,
  employeeUpdateSuccess: true,
  employeeListUpdateNeeded: true,
});

const updateEmployeeFail = (
  state: EmployeeStateType,
  action: EmployeeActionType,
): EmployeeStateType => ({
  ...state,
  employeeUpdateLoading: false,
  employeeUpdateError: action.employeeUpdateError,
});

const deleteEmployeeStart = (state: EmployeeStateType): EmployeeStateType => ({
  ...state,
  employeeDeleteLoading: true,
});

const deleteEmployeeSuccess = (
  state: EmployeeStateType,
): EmployeeStateType => ({
  ...state,
  employeeDeleteLoading: false,
  employeeDeleteError: null,
  employeeDeleteSuccess: true,
  employeeListUpdateNeeded: true,
});

const deleteEmployeeFail = (
  state: EmployeeStateType,
  action: EmployeeActionType,
): EmployeeStateType => ({
  ...state,
  employeeDeleteLoading: false,
  employeeDeleteError: action.employeeDeleteError,
});

const resetEmployeeStore = (): EmployeeStateType => ({
  ...initialState,
});

const changeEmployeeStatusStart = (
  state: EmployeeStateType,
): EmployeeStateType => ({
  ...state,
  changeEmployeeStatusLoading: true,
});

const changeEmployeeStatusSuccess = (
  state: EmployeeStateType,
): EmployeeStateType => ({
  ...state,
  changeEmployeeStatusLoading: false,
  changeEmployeeStatusError: null,
  changeEmployeeStatusSuccess: true,
  employeeListUpdateNeeded: true,
});

const changeEmployeeStatusFail = (
  state: EmployeeStateType,
  action: EmployeeActionType,
): EmployeeStateType => ({
  ...state,
  changeEmployeeStatusLoading: false,
  changeEmployeeStatusError: action.changeEmployeeStatusError,
});

const fetchAllEmployeesStart = (
  state: EmployeeStateType,
): EmployeeStateType => ({
  ...state,
  allEmployeesLoading: true,
});

const fetchAllEmployeesSuccess = (
  state: EmployeeStateType,
  action: EmployeeActionType,
): EmployeeStateType => ({
  ...state,
  allEmployees: action.allEmployees,
  allEmployeesLoading: false,
  allEmployeesError: null,
});

const fetchAllEmployeesFail = (
  state: EmployeeStateType,
  action: EmployeeActionType,
): EmployeeStateType => ({
  ...state,
  allEmployeesError: action.employeeError,
  allEmployeesLoading: false,
});

const completelyResetEmployeeStore = (): EmployeeStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: EmployeeActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_EMPLOYEE_START:
      return fetchEmployeeStart(state);
    case actionTypes.FETCH_EMPLOYEE_SUCCESS:
      return fetchEmployeeSuccess(state, action);
    case actionTypes.FETCH_EMPLOYEE_FAIL:
      return fetchEmployeeFail(state, action);
    case actionTypes.FETCH_EMPLOYEES_START:
      return fetchEmployeesStart(state);
    case actionTypes.FETCH_EMPLOYEES_SUCCESS:
      return fetchEmployeesSuccess(state, action);
    case actionTypes.FETCH_EMPLOYEES_FAIL:
      return fetchEmployeesFail(state, action);
    case actionTypes.CREATE_EMPLOYEE_START:
      return createEmployeeStart(state);
    case actionTypes.CREATE_EMPLOYEE_SUCCESS:
      return createEmployeeSuccess(state);
    case actionTypes.CREATE_EMPLOYEE_FAIL:
      return createEmployeeFail(state, action);
    case actionTypes.UPDATE_EMPLOYEE_START:
      return updateEmployeeStart(state);
    case actionTypes.UPDATE_EMPLOYEE_SUCCESS:
      return updateEmployeeSuccess(state);
    case actionTypes.UPDATE_EMPLOYEE_FAIL:
      return updateEmployeeFail(state, action);
    case actionTypes.DELETE_EMPLOYEE_START:
      return deleteEmployeeStart(state);
    case actionTypes.DELETE_EMPLOYEE_SUCCESS:
      return deleteEmployeeSuccess(state);
    case actionTypes.DELETE_EMPLOYEE_FAIL:
      return deleteEmployeeFail(state, action);
    case actionTypes.CHANGE_EMPLOYEE_STATUS_START:
      return changeEmployeeStatusStart(state);
    case actionTypes.CHANGE_EMPLOYEE_STATUS_SUCCESS:
      return changeEmployeeStatusSuccess(state);
    case actionTypes.CHANGE_EMPLOYEE_STATUS_FAIL:
      return changeEmployeeStatusFail(state, action);
    case actionTypes.FETCH_ALL_EMPLOYEES_START:
      return fetchAllEmployeesStart(state);
    case actionTypes.FETCH_ALL_EMPLOYEES_SUCCESS:
      return fetchAllEmployeesSuccess(state, action);
    case actionTypes.FETCH_ALL_EMPLOYEES_FAIL:
      return fetchAllEmployeesFail(state, action);
    case actionTypes.RESET_EMPLOYEE_STORE:
      return resetEmployeeStore();
    case actionTypes.LOGOUT:
      return completelyResetEmployeeStore();
    default:
      return state;
  }
};

export default reducer;
